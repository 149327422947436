<template>
  <z-section-divider :title="$t('guest.address')" start-opened>
    <template #content>
      <div class="address-section">
        <div class="cep-state-container grid-span-2">
          <div class="cep-link-container">
            <z-input
              v-if="
                !config.addressData?.zipcode?.default &&
                guest.countryId === 'BR'
              "
              key="with-mask"
              inputmode="numeric"
              mask="#####-###"
              data-cy="zipcode"
              :loading="isLoadingCep"
              :required="Boolean(v$.zipCode.required)"
              :disabled="disabled"
              :label="$t('guest.zipCode')"
              :value="guest.zipCode"
              :error-messages="getErrorMessages(v$.zipCode)"
              @input="$emit('change', { zipCode: $event })"
            />

            <z-input
              v-else-if="
                !config.addressData?.zipcode?.default &&
                guest.countryId !== 'BR'
              "
              key="without-mask"
              inputmode="numeric"
              data-cy="zipcode"
              :loading="isLoadingCep"
              :required="Boolean(v$.zipCode.required)"
              :disabled="disabled"
              :label="$t('guest.zipCode')"
              :value="guest.zipCode"
              :error-messages="getErrorMessages(v$.zipCode)"
              @input="$emit('change', { zipCode: $event })"
            />

            <a
              v-if="
                !disabled &&
                !config.addressData?.zipcode?.default &&
                guest.countryId === 'BR'
              "
              class="mb-6 ml-2"
              style="text-decoration: underline !important; font-size: 0.75rem"
              @click="$emit('click:open-forgot-adress')"
            >
              {{ $t("guest.i_dont_know_my_zipcode") }}
            </a>
          </div>

          <z-autocomplete
            v-if="
              !config.addressData?.state?.default && guest.countryId === 'BR'
            "
            item-text="uf"
            item-value="id"
            data-cy="state"
            :disabled="disabled"
            :autocomplete="Math.random()"
            :label="$t('guest.state')"
            :items="states"
            :value="guest.stateId"
            :error-messages="getErrorMessages(v$.stateId)"
            @change="$emit('change', { stateId: $event })"
          />

          <z-input
            v-else-if="
              !config.addressData?.state?.default && guest.countryId !== 'BR'
            "
            data-cy="state"
            :disabled="disabled"
            :autocomplete="Math.random()"
            :label="$t('guest.state')"
            :value="guest.stateName"
            @input="$emit('change', { stateName: $event })"
          ></z-input>
        </div>

        <z-autocomplete
          v-if="!config.addressData?.city?.default && guest.countryId === 'BR'"
          item-text="name"
          item-value="id"
          data-cy="city"
          :disabled="disabled"
          :autocomplete="Math.random()"
          :label="$t('guest.city')"
          :items="cities"
          :value="guest.cityId"
          :error-messages="getErrorMessages(v$.cityId)"
          @change="$emit('change', { cityId: $event })"
        />

        <z-input
          v-else-if="
            !config.addressData?.city?.default && guest.countryId !== 'BR'
          "
          data-cy="city"
          :disabled="disabled"
          :autocomplete="Math.random()"
          :label="$t('guest.city')"
          :value="guest.cityName"
          @input="$emit('change', { cityName: $event })"
        ></z-input>

        <z-input
          v-if="!config.addressData?.neighborhood?.default"
          data-cy="neighborhood"
          :required="Boolean(v$.neighborhood.required)"
          :disabled="disabled"
          :label="$t('guest.neighborhood')"
          :value="guest.neighborhood"
          :error-messages="getErrorMessages(v$.neighborhood)"
          @input="$emit('change', { neighborhood: $event })"
        />

        <z-input
          v-if="!config.addressData?.neighborhood?.default"
          class="grid-span-2"
          data-cy="address"
          :mask="addressMask(100)"
          :required="Boolean(v$.address.required)"
          :disabled="disabled"
          :label="$t('guest.address')"
          :value="guest.address"
          :error-messages="getErrorMessages(v$.address)"
          @input="$emit('change', { address: $event })"
        />

        <div class="number-complement-container grid-span-2">
          <z-input
            v-if="!config.addressData?.addressNumber?.default"
            inputmode="numeric"
            data-cy="address-number"
            :required="Boolean(v$.addressNumber.required)"
            :disabled="disabled"
            :label="$t('guest.address_number')"
            :value="guest.addressNumber"
            :error-messages="getErrorMessages(v$.addressNumber)"
            @input="$emit('change', { addressNumber: $event })"
          />

          <z-input
            v-if="!config.addressData?.addressComplement?.default"
            data-cy="complement"
            :disabled="disabled"
            :label="$t('guest.address_complement')"
            :value="guest.addressComplement"
            :error-messages="getErrorMessages(v$.addressComplement)"
            @input="$emit('change', { addressComplement: $event })"
          />
        </div>
      </div>
    </template>
  </z-section-divider>
</template>

<script>
import { inject } from "vue"

import { addressMask } from "@/consts"

export default {
  props: {
    guest: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    cities: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    getErrorMessages: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const isLoadingCep = inject("isLoadingCep")

    return { isLoadingCep, addressMask }
  },
}
</script>

<style lang="scss" scoped>
.cep-state-container {
  display: grid;
  column-gap: 15px;
  grid-template-columns: 2fr 1fr;
}

.cep-link-container {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.number-complement-container {
  display: grid;
  column-gap: 15px;
  grid-template-columns: 90px 1fr;
}

.address-section {
  display: grid;
  column-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
}
</style>
